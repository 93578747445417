import React from "react";
import "./css/index.css";
import io from "socket.io-client";
import { animateScroll } from "react-scroll";

class MessageView extends React.Component {
	state = {
		messages: [],
	};

	componentDidMount() {
		this.socket = io("https://hkzchatroom-server.herokuapp.com/");
		this.socket.on("messages", (e) => {
			this.setState({ messages: [...this.state.messages, e] });
			console.log(this.state.messages);
		});
		this.scrollToBottom();
	}

	componentDidUpdate() {
		this.scrollToBottom();
	}

	message = React.createRef();
	messagesBottom = React.createRef();

	send = () => {
		let message = this.message.current.value;
		let date = new Date().toISOString();
		if ("" !== message) {
			this.socket.emit("send", message, date);
			this.message.current.value = "";
		}
	};

	scrollToBottom = () => {
		animateScroll.scrollToBottom({
			containerId: "messages-scroll",
			smooth: true,
			duration: 500,
		});
	};
	render() {
		return (
			<div className="message-view">
				<div className="sub-message-view">
					<Header
						name="PY780N"
						status="online"
						img="https://avatars1.githubusercontent.com/u/28890199?s=60&v=4"
					/>
					<MessageList>
						{this.state.messages.map((e, key) => {
							return (
								<Message
									key={key}
									date={e.date}
									message={e.message}
									by={e.by}
								/>
							);
						})}
					</MessageList>

					<InputBox message={this.message} action={this.send} />
				</div>
			</div>
		);
	}
}

const MessageList = (props) => {
	return (
		<div className="message-main">
			<div className="message-list" id="messages-scroll">
				{props.children}
			</div>
		</div>
	);
};

const Message = (props) => {
	return (
		<div className="message" id={props.by}>
			<p>{props.message}</p>
			<span>- {props.date} -</span>
		</div>
	);
};

const InputBox = (props) => {
	const send = () => {
		props.action();
	};
	return (
		<div className="input-box">
			<i className="material-icons">apps</i>
			<i className="material-icons">photo</i>
			<i className="material-icons">photo_camera</i>
			<input
				type="text"
				placeholder="Enter Message Here...."
				ref={props.message}
				onKeyDown={(e) => {
					if (e.key === "Enter") send();
				}}
			/>
			<input
				type="submit"
				value="send"
				className="material-icons"
				onClick={send}
			/>
		</div>
	);
};

const Header = (props) => {
	return (
		<div className="header">
			<span className="text-truncate">{props.name}</span>
			<img src={props.img} alt="" className={props.status} />
		</div>
	);
};

export default MessageView;
