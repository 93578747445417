import React, { useEffect } from "react";
import PropTypes from "prop-types";
import io from "socket.io-client";
import "./ChatRoom.css";

const propTypes = {};

class ChatRoom extends React.Component {
	state = {
		messages: [{ message: "welcome", by: "other" }],
		name: null,
	};

	message = React.createRef();

	componentDidMount() {
		this.socket = io("https://hkzchatroom.herokuapp.com/");
		this.socket.on("messages", (e) => {
			this.setState({ messages: [...this.state.messages, e] });
			console.log(this.state.messages);
		});
	}

	send = () => {
		let message = this.message.current.value;
		if (message !== "") {
			this.socket.emit("send", message);
			this.message.current.value = "";
		}
	};

	render() {
		return (
			<div className="chat-room-container">
				<p>{this.state.log}</p>
				<div className="sub-container">
					<Messages>
						{this.state.messages.map((e, key) => {
							return <Message key={key} message={e.message} by={e.by} />;
						})}
					</Messages>

					<div onSubmit={this.send} className="input-layout">
						<input
							type="text"
							placeholder="enter message"
							onKeyDown={(e) => {
								if (e.keyCode === 13) this.send();
							}}
							ref={this.message}
						/>
						<input
							type="submit"
							className="material-icons"
							onClick={this.send}
							value="send"
						/>
					</div>
				</div>
			</div>
		);
	}
}

const Messages = (props) => {
	return <div className="messages">{props.children}</div>;
};

const Message = (props) => {
	return <p className={props.by}>{props.message}</p>;
};

ChatRoom.propTypes = propTypes;

export default ChatRoom;
