import React from "react";
import "./css/index.css";

class Sidebar extends React.Component {
	state = {
		slide: false,
		icon: "menu",
		id: null,
	};

	Slide = () => {
		if (this.state.slide) {
			this.setState({
				id: "close",
				slide: false,
				icon: "menu",
			});
		} else {
			this.setState({
				id: "open",
				slide: true,
				icon: "clear",
			});
		}
	};
	render() {
		return (
			<div className="sidebar" id={this.state.id}>
				<Menu />
				<FriendList>
					<Friend
						name="Hein Ko Zin"
						message="Hello!"
						status="online"
						enter="active"
					/>
					<Friend name="Gon Gyi" message="Hi!" status="offline" />
					<Friend name="Kyaw San" message="How are you?" status="online" />
				</FriendList>
				<SlideButton slide={this.Slide} icon={this.state.icon} />
			</div>
		);
	}
}

const Menu = () => (
	<div className="menu">
		<div>
			<a href="">
				<i className="material-icons">person_add</i>
			</a>
			<a href="">
				<i className="material-icons">settings</i>
			</a>
			<a href="">
				<i className="material-icons">error</i>
			</a>
		</div>
	</div>
);

const FriendList = (props) => {
	return (
		<div className="friend-list">
			<div>{props.children}</div>
		</div>
	);
};

const Friend = (props) => {
	return (
		<div className="friend text-truncate d-block" id={props.enter}>
			<img
				src="https://avatars1.githubusercontent.com/u/28890199?s=60&v=4"
				className={props.status}
				alt=""
			/>
			<a href="">
				<span>{props.name}</span>
			</a>
			<p className="text-truncate d-block">{props.message}</p>
		</div>
	);
};

const SlideButton = (props) => {
	const slide = () => {
		props.slide();
	};
	return (
		<i className="material-icons slideBtn" onClick={slide}>
			{props.icon}
		</i>
	);
};

export default Sidebar;
