import React, { useState } from "react";
import "./css/App.css";
import "bootstrap/dist/css/bootstrap.css";
import ChatRoom from "./components/ChatRoom/ChatRoom.jsx";
import Sidebar from "./components/Sidebar";
import MessageView from "./components/MessageView";


function App() {


  return (
    <div className="App">
      <Sidebar />
      <MessageView />
      {/* <ChatRoom /> */}
    </div>
  );
}

export default App;
